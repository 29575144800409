import {
  Tag,
  Col,
  Icon,
  Toast,
  Button,
  SwipeCell,
  Dialog,
  Tab,
  Tabs,
  Row,
  NavBar,
  Tabbar,
  TabbarItem,
  Panel,
  List,
  Popup,
  Picker,
  Uploader,
  Info,
  Rate,
  Image,
  ImagePreview
} from 'vant';

const components = [
  Tag,
  Col,
  Icon,
  Toast,
  Button,
  SwipeCell,
  Dialog,
  Tab,
  Tabs,
  Row,
  NavBar,
  Tabbar,
  TabbarItem,
  Panel,
  List,
  Popup,
  Picker,
  Uploader,
  Info,
  Rate,
  Image,
  ImagePreview
];

export default (Vue) => {
  components.forEach(Component => {
    Vue.component(Component.name, Component);
  });
};
