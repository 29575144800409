import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/proList'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/proList',
    name: 'proList',
    component: () => import('../views/ProList.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/proDetail/:id',
    name: 'proDetail',
    component: () => import('../views/ProDetail.vue')
  },
  {
    path: '/flightCheck/:id',
    name: 'flightCheck',
    component: () => import('../views/FlightCheck.vue')
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('../views/Personal.vue')
  },
  {
    path: '/updatePassword',
    name: 'updatePassword',
    component: () => import('../views/UpdatePassword.vue')
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // 按下 后退/前进 按钮时，类似浏览器的原生表现
    } else {
      return { x: 0, y: 0 }; // 让页面滚动到顶部
    }
  }
});

export default router;
