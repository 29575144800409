import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'amfe-flexible';

import api from './api';
import http from '@/utils/request';
import { ImagePreview } from 'vant';
import axios from 'axios';

Vue.prototype.$api = api;
Vue.prototype.http = http;
Vue.prototype.$imagePreview = ImagePreview;

import components from './utils/components.js';
Vue.use(components);

Vue.config.productionTip = false;

// 全局启用 withCredentials
axios.defaults.withCredentials = true;

// 设置基础 URL
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

// 添加到 Vue 原型链
Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
